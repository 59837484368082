const fr_FR = {}

fr_FR.strings = {
  addBulkFilesFailed: {
    '0': 'L\'ajout de %{smart_count} fichier a ÃÂ©chouÃÂ©',
    '1': 'L\'ajout de %{smart_count} fichiers a ÃÂ©chouÃÂ©',
  },
  addMore: 'Ajouter d\'autres',
  addMoreFiles: 'Ajouter d\'autres fichiers',
  addingMoreFiles: 'En train d\'ajouter des fichiers',
  allFilesFromFolderNamed: 'Tous les fichiers du dossier %{name}',
  allowAccessDescription: 'Pour prendre des photos ou enregistrer une vidÃÂ©o avec votre camÃÂ©ra, veuillez autoriser l\'accÃÂ¨s ÃÂ  votre camÃÂ©ra pour ce site.',
  allowAccessTitle: 'Veuillez autoriser l\'accÃÂ¨s ÃÂ  votre camÃÂ©ra',
  authAborted: 'Authentification interrompue',
  authenticateWith: 'Se connecter ÃÂ  %{pluginName}',
  authenticateWithTitle: 'Veuillez vous authentifier avec %{pluginName} pour sÃÂ©lectionner les fichiers',
  back: 'Retour',
  browse: 'naviguer',
  browseFiles: 'naviguer',
  cancel: 'Annuler',
  cancelUpload: 'Annuler tÃÂ©lÃÂ©chargement',
  chooseFiles: 'Choisir des fichiers',
  closeModal: 'Fermer FenÃÂªtre',
  companionError: 'Connexion ÃÂ  Companion a ÃÂ©chouÃÂ©',
  companionUnauthorizeHint: 'Pour vous dÃÂ©connecter de votre compte %{provider}, veuillez aller ÃÂ  %{url}',
  complete: 'TerminÃÂ©',
  connectedToInternet: 'ConnectÃÂ© ÃÂ  Internet',
  copyLink: 'Copier lien',
  copyLinkToClipboardFallback: 'Copier le lien ci-dessous',
  copyLinkToClipboardSuccess: 'Lien copiÃÂ©',
  creatingAssembly: 'PrÃÂ©paration du tÃÂ©lÃÂ©chargement...',
  creatingAssemblyFailed: 'Transloadit: Impossible de crÃÂ©er Assembly',
  dashboardTitle: 'TÃÂ©lÃÂ©chargeur de fichier',
  dashboardWindowTitle: 'FenÃÂªtre de tÃÂ©lÃÂ©chargeur de fichier (Appuyez sur echap pour fermer)',
  dataUploadedOfTotal: '%{complete} sur %{total}',
  done: 'TerminÃÂ©',
  dropHereOr: 'DÃÂ©poser les fichiers ici ou %{browse}',
  dropHint: 'DÃÂ©posez vos fichiers ici',
  dropPasteBoth: 'DÃÂ©poser les fichiers ici, coller ou %{browse}',
  dropPasteFiles: 'DÃÂ©poser les fichiers ici, coller ou %{browse}',
  dropPasteFolders: 'DÃÂ©poser les fichiers ici, coller ou %{browse}',
  dropPasteImportBoth: 'DÃÂ©poser les fichiers ici, coller, %{browse} ou importer de',
  dropPasteImportFiles: 'DÃÂ©poser les fichiers ici, coller, %{browse} ou importer de',
  dropPasteImportFolders: 'DÃÂ©poser les fichiers ici, coller, %{browse} ou importer de',
  editFile: 'Modifier le fichier',
  editFileWithFilename: 'Modifier le fichier %{file}',
  editing: 'Modification en cours de %{file}',
  emptyFolderAdded: 'Aucun fichier n\'a ÃÂ©tÃÂ© ajoutÃÂ© depuis un dossier vide',
  encoding: 'Traitement...',
  enterCorrectUrl: 'Lien incorrect: Assurez-vous que vous entrez un lien direct vers le fichier',
  enterUrlToImport: 'Entrez le lien pour importer un fichier',
  exceedsSize: 'Ce fichier dÃÂ©passe la taille maximale autorisÃÂ©e de %{size}',
  failedToFetch: 'Companion a ÃÂ©chouÃÂ© ÃÂ  rÃÂ©cupÃÂ©rer ce lien, assurez-vous qu\'il est correct',
  failedToUpload: 'Le tÃÂ©lÃÂ©chargement de %{file} a ÃÂ©chouÃÂ©',
  fileSource: 'Fichier source: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} sur %{smart_count} fichier tÃÂ©lÃÂ©chargÃÂ©',
    '1': '%{complete} sur %{smart_count} fichiers tÃÂ©lÃÂ©chargÃÂ©s',
    '2': '%{complete} sur %{smart_count} fichiers tÃÂ©lÃÂ©chargÃÂ©s',
  },
  filter: 'Filtrer',
  finishEditingFile: 'Terminer l\'ÃÂ©dition du fichier',
  folderAdded: {
    '0': '%{smart_count} fichier ajoutÃÂ© de %{folder}',
    '1': '%{smart_count} fichiers ajoutÃÂ©s de %{folder}',
    '2': '%{smart_count} fichiers ajoutÃÂ©s de %{folder}',
  },
  generatingThumbnails: 'Generating thumbnails...',
  import: 'Importer',
  importFrom: 'Importer de %{name}',
  loading: 'Chargement...',
  logOut: 'DÃÂ©connexion',
  micDisabled: 'AccÃÂ¨s au micro refusÃÂ© par l\'utilisateur',
  myDevice: 'Mon Appareil',
  noDuplicates: 'Impossible d\'ajouter le fichier "%{fileName}", il existe dÃÂ©jÃÂ ',
  noFilesFound: 'Vous n\'avez aucun fichier ou dossier ici',
  noInternetConnection: 'Pas de connexion ÃÂ  Internet',
  noMoreFilesAllowed: 'Impossible d\'ajouter de nouveaux fichiers: en cours de chargement ',
  openFolderNamed: 'Ouvrir %{name}',
  pause: 'Pause',
  pauseUpload: 'Mettre en pause le tÃÂ©lÃÂ©chargement',
  paused: 'En pause',
  poweredBy: 'PropulsÃÂ© par %{uppy}',
  processingXFiles: {
    '0': 'Traitement de %{smart_count} fichier',
    '1': 'Traitement de %{smart_count} fichiers',
    '2': 'Traitement de %{smart_count} fichiers',
  },
  recording: 'Enregistrement',
  recordingLength: 'DurÃÂ©e d\'enregistrement %{recording_length}',
  recordingStoppedMaxSize: 'L\'enregistrement s\'est arrÃÂ©tÃÂ© car la taille du fichier dÃÂ©passe la limite',
  removeFile: 'Effacer le fichier %{file}',
  resetFilter: 'RÃÂ©initialiser filtre',
  resume: 'Reprendre',
  resumeUpload: 'Reprendre le tÃÂ©lÃÂ©chargement',
  retry: 'RÃÂ©essayer',
  retryUpload: 'RÃÂ©essayer le tÃÂ©lÃÂ©chargement',
  saveChanges: 'Sauvegarder les modifications',
  selectFileNamed: 'SÃÂ©lectionner le fichier %{name}',
  selectX: {
    '0': 'SÃÂ©lectionner %{smart_count}',
    '1': 'SÃÂ©lectionner %{smart_count}',
    '2': 'SÃÂ©lectionner %{smart_count}',
  },
  smile: 'Souriez !',
  startRecording: 'Commencer l\'enregistrement vidÃÂ©o',
  stopRecording: 'ArrÃÂªter l\'enregistrement vidÃÂ©o',
  streamActive: 'Stream actif',
  streamPassive: 'Stream passif',
  submitRecordedFile: 'Envoyer la vidÃÂ©o enregistrÃÂ©e',
  takePicture: 'Prendre une photo',
  timedOut: 'TÃÂ©lÃÂ©chargement bloquÃÂ© durant %{seconds} secondes, annulation.',
  unselectFileNamed: 'DÃÂ©sÃÂ©lectionner le fichier %{name}',
  upload: 'TÃÂ©lÃÂ©charger',
  uploadComplete: 'TÃÂ©lÃÂ©chargement terminÃÂ©',
  uploadFailed: 'Le tÃÂ©lÃÂ©chargement a ÃÂ©chouÃÂ©',
  uploadPaused: 'TÃÂ©lÃÂ©chargement mis en pause',
  uploadXFiles: {
    '0': 'TÃÂ©lÃÂ©charger %{smart_count} fichier',
    '1': 'TÃÂ©lÃÂ©charger %{smart_count} fichiers',
    '2': 'TÃÂ©lÃÂ©charger %{smart_count} fichiers',
  },
  uploadXNewFiles: {
    '0': 'TÃÂ©lÃÂ©charger +%{smart_count} fichier',
    '1': 'TÃÂ©lÃÂ©charger +%{smart_count} fichiers',
    '2': 'TÃÂ©lÃÂ©charger +%{smart_count} fichiers',
  },
  uploading: 'TÃÂ©lÃÂ©chargement en cours',
  uploadingXFiles: {
    '0': 'TÃÂ©lÃÂ©chargement de %{smart_count} fichier',
    '1': 'TÃÂ©lÃÂ©chargement de %{smart_count} fichiers',
    '2': 'TÃÂ©lÃÂ©chargement de %{smart_count} fichiers',
  },
  xFilesSelected: {
    '0': '%{smart_count} fichier sÃÂ©lectionnÃÂ©',
    '1': '%{smart_count} fichiers sÃÂ©lectionnÃÂ©s',
    '2': '%{smart_count} fichiers sÃÂ©lectionnÃÂ©s',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} autre fichier ajoutÃÂ©',
    '1': '%{smart_count} autres fichiers ajoutÃÂ©s',
    '2': '%{smart_count} autres fichiers ajoutÃÂ©s',
  },
  xTimeLeft: '%{time} restantes',
  youCanOnlyUploadFileTypes: 'Vous pouvez seulement tÃÂ©lÃÂ©charger: %{types}',
  youCanOnlyUploadX: {
    '0': 'Vous pouvez seulement tÃÂ©lÃÂ©charger %{smart_count} fichier',
    '1': 'Vous pouvez seulement tÃÂ©lÃÂ©charger %{smart_count} fichiers',
    '2': 'Vous pouvez seulement tÃÂ©lÃÂ©charger %{smart_count} fichiers',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Vous devez sÃÂ©lectionner au moins %{smart_count} fichier',
    '1': 'Vous devez sÃÂ©lectionner au moins %{smart_count} fichiers',
    '2': 'Vous devez sÃÂ©lectionner au moins %{smart_count} fichiers',
  },
}

fr_FR.pluralize = function pluralize (n) {
  if (n === 1) {
    return 0
  }
  return 1
}

if (typeof window !== 'undefined' && typeof window.Uppy !== 'undefined') {
  window.Uppy.locales.fr_FR = fr_FR
}

module.exports = fr_FR
